@media (min-width: 1011px) {
  main {
    overflow: auto;
    display: block;
    width: auto;
    height: auto;
  }
  .ant-btn-group {
    display: flex;
  }
}

@media (max-width: 600px) {
  .ant-btn-group {
    text-align: center;
  }
}

@media (max-width: 1010px) {
  main {
    overflow: auto;
    display: block;
    width: auto;
    height: auto;
  }
}

@media (max-width: 767px){
  .ant-modal {
    max-width: 100%;
  }
}

.ant-modal {
  color: #000000;
}



  html,
  body {
    overflow: hidden !important;
    padding: 0 0 0 0 !important;
    min-width: 300px;
    #root {
      width: 100%;
      height: 100%;
    }
  }

  ::selection {
    color: #fff;
  }

  .ant-tabs-nav-scroll {
    user-select: none;
  }

  .ant-calendar-ok-btn {
    color: #fff !important;
  }

  div[role=tab],
  button {
    cursor: pointer;
  }
  
  .scrollarea .scrollbar-container {
    z-index: inherit !important;
  }

  .ant-checkbox {
    .ant-checkbox-inner {
      transition: none;
    }

    .ant-checkbox-inner::after {
      transition: none;
    }
  }
  
  .react-grid-item {
    transition: none;
    transition-property: none;
  }

  .ant-checkbox-checked::after {
    transition: none;
    border: none;
    animation: none;
  }

  .react-grid-placeholder {
    background: #EEEEEE !important;
  }

  .utils__scrollTable {
    white-space: normal
  }

  .trash-undo {
    color: #00800050;
  }
  
  .trash-undo:hover {
    color: green;
  }
  
  .trash-delete {
    color: #ff000050;
  }
  
  .trash-delete:hover {
    color: red;
  }

  .dp-table-deleted-row {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f2f4f8;
    border-color: #e4e9f0;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    .src-avatar {
      filter: grayscale(100%);
    }
  }

  .ant-calendar-selected-day .ant-calendar-date {
    color: white !important;
  }
  

  .ant-table-content {
    .ant-table-body {
      .ant-table-fixed {
        .ant-table-tbody {
          .ant-table-row {
            td {
              .title {
                cursor: pointer;
                display: block;
                min-height: 15px;
                min-width: 10px;
              }
            }

            .sideButtons {
              visibility: hidden;

              background: white;
              padding: 5px;
              border-radius: 5px;
              text-align: right;
              padding-right: 6px;

              .right-icon:first-child, .left-icon:first-child {
                margin-left: 0;
              }
            }
          }

          .sideButtonsVis {
            padding: 5px;
            border-radius: 5px;
            text-align: right;
            padding-right: 6px;

            .right-icon:first-child, .left-icon:first-child {
              margin-left: 0;
            }
          }

          .ant-table-row:hover .sideButtons {
            visibility: visible;
          }
        }
      }
    }
  }

  .dp-sortable-table {
    .ant-table-content {
      .ant-table-body {
        .ant-table-fixed {
          .ant-table-thead {
            display: table-header-group;
          }
          .ant-table-tbody {
            .ant-table-row {
              transition: none;
            }
          }
        }
      }
    }
  }

  .results {
    padding-top: 5px;
    .ant-tabs-bar {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      .ant-tabs-extra-content {
        color: #78749b;
        display: flex;
        flex: 1;
        justify-content: space-between;
        .ant-tabs-tab:hover {
          color: #0d0c0c;
        }
      }
    }
  }

  .dp-list {
    .ant-list-items {
      .ant-list-item {
        .ant-list-item-meta {
          span {
            cursor: pointer;
          }
        }

        .sideButtons {
          visibility: hidden;
        }
      }

      .ant-list-item:hover .sideButtons {
        visibility: visible;
      }
    }
  }

  .settings {
    .ant-radio-group {
      .ant-radio-button-wrapper {
        background: linear-gradient(0, #f4f4f4 40%, rgb(255, 255, 255) 100%);
        color: #443f65;
        font-weight: 700;
      }
      .ant-radio-button-wrapper-checked {
        background: radial-gradient(circle, rgba(229,229,229,1) 50%, rgba(201,201,201,1) 100%);
        box-shadow: none;
        border: none;
      }
    }
  }

  .dp-card {
    .card {
      border: none;
      .card-header {
        padding: 8px 0;

        .utils__title {
          strong {
            margin: 0;
          }
        }

        .ant-btn {
          background-color: white;
          color: #333333;
          text-shadow: none;
          border-radius: 4px;
          margin-right: 8px;
          border-color: #777777;
          font-size: 12px;
          padding: 2px 5px;
          height: auto;
        }
      }

      .card-body {
        padding: 0;

        .ant-table-thead {
          display: none;
        }

        .ant-table-content {
          .ant-table-body {
            overflow-y: hidden;

            .ant-table-fixed {
              .ant-table-row {
                td {
                  padding: 6px;
                  .sideButtons {
                    text-align: right;
                  }
                }
              }
            }
          }
        }
      }
    }
  }


  .src-avatar {
    .ant-avatar {
      background: none;
      .ant-avatar-string {
        position: unset;
      }
    }
  }

  .bg-avatar {
    min-width: 100%;
    min-height: 100%;
    background-position: center;
    background-size: cover;
  }

  .production-tabs {
    .ant-tabs {
      overflow: unset;
    }
  }

  .ant-modal-wrap.draggable {
    overflow: hidden;
    background: rgba(78, 78, 78, 0.55);

    .ant-modal {

      .ant-modal-content {
        display: flex;
        flex-direction: column;
        height: 100%;

        .ant-modal-header {}

        .ant-modal-body {
          flex: 1;
          overflow: auto;
        }
      }
    }
  }

  .ant-modal-wrap.fixed {
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-modal {
      position: unset;
    }
  }

  .ant-modal-wrap.fullscreen {
    overflow: hidden;

    .ant-modal {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      .ant-modal-content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 0;
        display: flex;
        flex-direction: column;

        .ant-modal-header {
          height: 55px;
        }

        .ant-modal-body {
          flex: 1;
          overflow: auto;
        }
      }
    }
  }

.ant-modal-wrap.non-mask {
  position: unset;

  .ant-modal {
    position: fixed;
    z-index: 1000;
  }
}

.widget-modal {
  .ant-modal {
    top: 80px !important;
    right: 15px !important;
    left: auto !important;
    bottom: auto !important;
  }
}

.ant-modal-wrap.non-resizeable {

  .ant-modal {
    .ant-design-draggable-modal-resize-handle {
      display: none;
    }
  }
}

.ant-modal-wrap.non-resizeable-width {
  .ant-modal {
    width: 800px !important;
  }
}

.ant-modal-wrap.non-resizeable-height {
  .ant-modal {
    height: 100% !important;
  }
  @media (min-width: 455px) {
    .ant-modal {
      max-height: 800px !important;
    }
  }
}

@media (max-width: 455px) {
  .ant-modal {
    width: 100% !important;
    height: 100% !important;
    left: 0 !important;
    top: 0 !important;
  }
}

.ant-modal-wrap.help {
  justify-content: flex-end;
  -webkit-align-items: center;
  align-items: flex-start;
  @media (min-width: 455px) {
    .ant-modal {
      top: 0 !important;
      right: 0px;
      left: calc(100% - 35em) !important;
    }
  }
  @media (max-width: 455px) {
    .ant-modal {
      width: 100% !important;
      left: 0 !important;
      top: 0 !important;
    }
  }
}

.ant-upload.ant-upload-select-picture-card {
  border-radius: 10px;

  .ant-upload {
    padding: 0;
  }
}

.task-check {
  width: 40px;
}

.deleteIconStyle {
  font-size: 25px;
  margin-top: 0.4%;
  margin-left: 5px;
}

.fillStar {
  color: #ffd700;
}

.emptyStar {
  cursor: pointer;
}

.right-icon {
  font-size: 20px;
  cursor: pointer;
  margin-left: 15px;
}

.left-icon {
  font-size: 20px;
  cursor: pointer;
  margin: 0 10px 0 5px;
}

.error-message {
  color: red;
  font-size: 1.5em
}

.error-message-account {
  text-align: right;
  margin-top: -20px;
  margin-bottom: 5px;
  padding-right: 7%;
}

.dp-card-setting-body {
  padding: 20px 20px 50px 20px;
}

.account {
  display: inline-block;
  background-color: #f5f3ee;
  color: #514c6a;
  padding: 2px 5px;
  font-size: 14px;
  /* margin-right: 20px; */
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  cursor: pointer;
}

.sideButtons {
  // margin-top: 0.4%;
  margin-left: 5px;
  font-size: 20px;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  // top: 3px;
  right: 10px;
}

.sideButtonsVis {
  // margin-top: 0.4%;
  margin-left: 5px;
  font-size: 20px;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  // top: 3px;
  right: 10px;
}

.btn-title-row {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.sideImage {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.editIcon {
  font-size: 25px;
  margin-left: 5px;
}

.user-form {
  div.store-type{
    div:nth-child(2){
      div{
        span{
          p{
            text-align: left;
            margin-top: 8px;
            font-weight: bold;
            padding: 0px;
          }
        }
       
      }
    }
  }
  div {
    div:first-child {
      text-align: right;
      padding-right: 10px;

      label {
        padding-top: 7px;
      }
    }
    
  }
  div.partner-checkbox{
    div:nth-child(2){
      div{
        text-align: left;
      }
    }
    
    // div{
    //   background-color: red;
    //   // div{
    //   //   background-color: red;
    //   // }
    // }
  }
  
}

.success-message {
  font-size: 1.5em;
  color: rgb(23, 230, 40)
}

.card-header {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.utils__title {
  font-size: rem(16);
  line-height: rem(22);
  display: flex;
  justify-content: space-between;
  align-items: center;

  >strong {
    display: inline-block;
    margin-bottom: 10px;
  }
}

.service-button{
  margin: 10px;
}
.frontpage-text-editor{
  margin-bottom: 20px;
  div:nth-child(2){
    div.DraftEditor-root{
      height: 300px;
    }
  }
}
.frontpage-save-btn{
  text-align: right;
}
.export-date-picker-dropdown{
  .ant-calendar-range .ant-calendar-in-range-cell::before{
    background-color: #eee;
  }
}