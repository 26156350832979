@import 'assets/styles/mixins.scss';
.success {
  text-align: center;
  color: #37bd02;
  font-size: 16px;
}
.fails {
  text-align: center;
  color: #f40c0c;
  font-size: 16px;
}

.upload {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile {
  width: 100%;
  height: 104px;
  border-radius: 7px;
  display: flex;
  justify-content: center;
}

.image {
  border-radius: 7px;
}


.success-messgae {
  color: green;
}
.error {
  text-align: center;
    color: red;
    padding: 10px;
}
.errorStyle {
  text-align: center;
  color: red;
  padding: 10px;
}

.requipment {
  font-family: avenir;
  font-size: 14px;
  color: #0d4603;
}

.requipment span {
  box-shadow: 0 3px 6px 0 #00000017;
  background-color: var(--white);
  font-size: 12px;
  font-weight: 500;
  color: #2f3b00;
  border-radius: 15px;
  display: inline-block;
  padding: 5px 15px;
  margin: 0 5px;
  line-height: 1.4;
}

.requipment {
  .cross {
    display: inline-flex;
    padding: 0px;
    margin-left: -5px;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    overflow: hidden;
    background-color: black;
    color: white;
    font-family: sans-serif;
    opacity: 0.6;
    cursor: pointer;
  }
}