@import 'assets/styles/mixins.scss';

.topbar {
  background: $white;
  padding: 0 rem(20);
  // min-height: 64px;
  height: 64px;
  border-bottom: 1px solid $gray-border;
  color: $text;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  z-index: 1;
}

@media screen and (max-width: '1010px') {
  .topbar {
    flex-direction: column;
    height: auto;
    padding: 8px 0;
  }

  .btns {
    padding: 15px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btns button {
    margin: 0 3px;
  }

  .search {
    // margin: 0 auto !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.dropdown {
  display: flex;
  max-width: 150px;
  overflow: hidden;
}

.right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  @media (max-width: 500px) {
    padding: 0 40px;
    flex: none;
  }
}

.combines {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 0;
}

// .logo {
//   flex: 1
// }

.logo {
  img {
    width: 180px;
    margin-right: 40px;
  }
  @media (max-width: 1010px) {
    img {
      margin-right: 0;
    }
  }
}
@media (min-width: 1011px) {
  .hamburger {
    display: none;
  }
}

@media (max-width: 1010px) {
  .logo, .btns {
    display: none;
  }
  .hamburger {
    width: 100%;
    .toggler {
      height: 2.5em;
    }
    .logo {
      display: block;
    }
    .item {
      width: 100%;
      font-size: 13px;
      padding: 10px 0;
      border-bottom: 1px solid #e8e8e8;
    }
  }
}
