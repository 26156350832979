.loading{
    display: flex;
    justify-content: center;
    align-items: center;
}

.hide{
    overflow: hidden;
    width: 0;
    height: 0;
    display: block;
}