.donecheck {
  .ant-checkbox {
    transform: scale(1);
    top: 5%;

    .ant-checkbox-inner {
      border-radius: 100%;
      padding: 8px;
      width: 25px;
      height: 25px;
    }
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background: green;
      border-color: green;

      &:after
      {
        transform-origin: center center;
        transform: rotate(45deg) scale(1.2) translate(-20%, -55%);
      }
    }
  }
}