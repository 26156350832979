// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN DROPDOWN */

.ant-dropdown-menu {
  border: 1px solid #e9e9e9;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  font-size: rem(13);
  padding: rem(8) rem(20);
}

.ant-dropdown-menu-item > a,
.ant-dropdown-menu-submenu-title > a {
  padding: rem(8) rem(20);
  margin: rem(-8) rem(-20);
}

.ant-dropdown-menu-item > a.btn,
.ant-dropdown-menu-submenu-title > a.btn {
  margin: rem(-8) 0;
}

.ant-dropdown-menu-item-group-list {
  list-style: none;
  padding: 0;
}

.ant-dropdown-menu-item-group-title {
  font-weight: bold;
  font-size: rem(13);
  padding: rem(12) rem(20);
  color: $text;
}
.ant-select-dropdown-menu-item-active{
  background-color: #f2f4f8 !important;
}

.ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover{
  background-color: #f2f4f8;
}


.ant-dropdown-menu-item-selected, .ant-dropdown-menu-submenu-title-selected{
  background-color: #f2f4f8;
}