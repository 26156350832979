// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN MODAL */

.ant-modal-close {
  outline: none !important;
}


body{
  background-color: orange;
}